<template>
  <div>
    <div class="privacy_en" v-if="lang === 'en'">
      <p @click="lang='ru'" class="privacy_lang_link">Перейти на русский язык</p>
      <div><h1>Privacy Policy</h1>
        <div>3 August 2021</div>
      </div>
      <p>1. What This Privacy Policy Governs
      </p><p>This privacy policy (hereinafter - the Policy) applies to all information
      including personal data in the understanding of applicable law (hereinafter - "Personal Information"),
      which LLC "Event Link" can get about you in the course of your use of any sites, programs, products
      and / or Event Link services (hereinafter referred to as the "Services"), information about which you can find on the sites
      evli.in, evli.online and their subdomains, as well as during the execution of Event Link any agreements and contracts,
      concluded with you in connection with your use of the Services.
      Event Link may also receive Personal Information from its users (hereinafter - "Users"),
      sites or services that you use (hereinafter - "Sites")
      Event Link may also receive Personal Information from Users' subscribers (hereinafter referred to as "Subscribers"),
      using
      sites or services of Users
      In such cases, the transfer of Personal Information is possible only in cases established by the applicable
      legislation, and is carried out on the basis of agreements between Event Link and each of the Users or Subscriber.

    </p><p>The use of the Service by the User or Subscriber is governed by this Agreement, as well as
      Agreement posted at: https://evli.in/#/license

    </p><p>Please note that use of any of the Sites and / or Services may be subject to regulation.
      additional conditions that may make changes and / or additions to this Policy,
      and / or have special conditions in relation to personal information,
      posted in the relevant sections of the documents for such Sites / or Services.

    </p><p>2. Who processes the information
      To ensure your use of the Sites and Services, your Personal Information is collected and
      used by Event Link.

    </p><p>3. What is the purpose of this Policy
      Protecting your Personal Information and your privacy is extremely important to Event Link.
      Therefore, when you use the Sites and Services, Event Link protects and processes your Personal Information
      in strict accordance with applicable law.

    </p><p>4. What Personal Information Yandex collects about you

    </p><p>Event Link may collect the following categories of Personal Information about you when you use the Services:

    </p><p>(i) Personal information provided by you when registering (creating an account), such as your name,
      phone number, e-mail, as well as information provided by the Subscriber when subscribing to the calendars of the Users;

    </p><p>(ii) electronic data (HTTP headers, IP address, cookies, web beacons / pixel tags, data about
      browser ID, hardware and software information, wi-fi network data);

    </p><p>(iii) the date and time of access to the Sites and / or Services;

    </p><p>(iv) information about your activity while using the Sites and / or Services;

    </p><p>(v) geolocation information;

    </p><p>(vi) other information about you that is necessary for processing in accordance with the terms governing the use of specific Yandex Sites or Services;

    </p><p>(vii) Subscriber information we receive from our Users

    </p><p>Event Link also uses cookies and web beacons (including pixel tags) to collect Personal Information and associate such personal information with your device and web browser.

    </p><p>5. What is the legal basis and purpose of processing your Personal Information
      Event Link processes your Personal Information only if:

    </p><p>(i) processing is necessary to fulfill the contractual obligations of Event Link and Subscribers and Users,
      including ensuring the operation of the Services;

    </p><p>(ii) the processing is necessary to comply with statutory obligations;

    </p><p>Use of information received from the Google API in the Event Link Services (hereinafter referred to as the "Received Data"),
      is carried out in accordance with the provisions of the Google API Disclosure subject to the restrictions specified therein

    </p><p>6. How Event Link protects your Personal Information
    In most cases, Personal Information is processed automatically without anyone having access to it.
    from the Event Link staff. If such access is needed, then it can be provided
    only to those Event Link employees who need it to complete their tasks.
    To protect and ensure the confidentiality of data, all employees must comply with internal policies and
    procedures regarding the processing of Personal Information. They must also follow all technical and
    organizational security measures in place to protect your Personal Information.

  </p><p>Event Link has also implemented sufficient technical and organizational measures to protect Personal Information.
    from unauthorized, accidental or illegal destruction, loss, alteration,
    abuse, disclosure or access, and other illegal forms of processing.

  </p><p>7. Where your Personal Information is stored and processed
    Your Personal Information will be stored in the Russian Federation.

  </p><p>8. How long do we keep your Personal Information
    Event Link will store your Personal Information for as long as it is necessary to achieve
    the purpose for which it was collected, or to comply with legal or regulatory requirements.

  </p><p>9. Your rights

  </p><p>If this is provided by applicable law, you have the right to access your Personal
    information processed by Event Link in accordance with this Policy.

  </p><p>If provided by applicable law, you have the right to:

  </p><p>familiarize yourself with the information that Event Link stores about you;
  </p><p>request the deletion of your Personal Information or part of it, as well as withdraw consent to processing
    Your personal information;
  </p><p>require restrictions on the processing of your Personal Information;
  </p><p>Event Link will comply with such requests in accordance with applicable law.

  </p><p>In cases provided for by applicable law, you may also have other rights,
    not listed above.

  </p><p>When you use the Services, the transfer of Personal Information is carried out with your consent,
    expressing free will and your interest, (in particular, on the basis of an agreement with you) and is not
    duty under the law.

  </p><p>If you are not satisfied with the way Event Link processes your Personal Information, please let us know
    and we will consider your claim.</p>
    <p>10. How we use cookies and other similar technologies on the Sites or when you use the Services

    </p><p>Cookies are a small piece of data that is received and processed by the device,
    which you use to access the Sites and Services. Cookies are stored and sent back to the Sites and Services
    information,
    which helps your work with the Sites and Services and allows us to remember your preferences after
    time, for example, browser settings or recognize your account.

  </p><p>Event Link uses the information contained in cookies only for the above purposes,
    after which the collected data will be stored on your device for a period
    which may depend on the respective type of cookies, but without exceeding the time limit,
    necessary to achieve their goal, after which they will be automatically removed from your system.

  </p><p>Personal information collected by cookies placed on your device may be transferred
    and is available to Event Link and / or Users.

  </p><p>Event Link can also use web beacons (pixel tags) to access cookies,
    previously placed on your device for the following purposes

  </p><p>12. Updating this Policy
    This Policy is subject to change. Event Link has the right to make changes at its own discretion,
    including, but not limited to, in cases where the corresponding changes are associated with changes in
    applicable law, as well as when the corresponding changes are related to changes in work
    Sites and Services.</p>
    </div>
    <div class="privacy_ru" v-if="lang === 'ru'">
      <p @click="lang='en'" class="privacy_lang_link">Switch to English</p>
      <div><h1>Политика конфиденциальности</h1>
        <div>Дата публикации: 3 августа 2021 г.</div>
      </div>
      <p>1. Что регулирует настоящая политика конфиденциальности
      </p><p>Настоящая политика конфиденциальности (далее — Политика) действует в отношении всей информации,
      включая персональные данные в понимании применимого законодательства (далее — «Персональная информация»),
      которую ООО «Ивент Линк» могут получить о Вас в процессе использования Вами любых сайтов, программ, продуктов
      и/или сервисов Ивент Линк (далее вместе — «Сервисы»), информацию о которых Вы можете найти на сайтах
      evli.in, evli.online и их поддоменах, а также в ходе исполнения Ивент Линк любых соглашений и договоров,
      заключенных с Вами в связи с использованием Вами Сервисов.
    </p><p>Ивент Линк может также получать Персональную информацию от своих пользователей (далее — «Пользователи»),
      сайты или сервисы которых Вы используете (далее - «Сайты»)
    </p><p>Ивент Линк может также получать Персональную информацию от подписчиков Пользователей (далее — «Подписчики»),
      использующих
      сайты или сервисы Пользователей
    </p><p>В таких случаях передача Персональной информации возможна только в случаях, установленных применимым
      законодательством, и осуществляется на основании договоров между Ивент Линк и каждым из Пользователей или Подписчиком.

    </p><p>Использование Пользователем или Подписчиком Сервиса регулируется настоящим Соглашением, а также
      Соглашением, размещенным по адресу: https://evli.in/#/license

    </p><p>Пожалуйста, обратите внимание, что использование любого из Сайтов и/или Сервисов может регулироваться
      дополнительными условиями, которые могут вносить в настоящую Политику изменения и/или дополнения,
      и/или иметь специальные условия в отношении персональной информации,
      размещенные в соответствующих разделах документов для таких Сайтов /или Сервисов.

    </p><p>2. Кто обрабатывает информацию
      Для обеспечения использования Вами Сайтов и Сервисов Ваша Персональная информация собирается и
      используется Ивент Линк.

    </p><p>3. Какова цель данной Политики
    </p><p>Защита Вашей Персональной информации и Вашей конфиденциальности чрезвычайно важны для Ивент Линк.
      Поэтому при использовании Вами Сайтов и Сервисов Ивент Линк защищает и обрабатывает Вашу Персональную информацию
      в строгом соответствии с применимым законодательством.

    </p><p>4. Какую Персональную информацию о Вас собирает Яндекс

    </p><p>Ивент Линк может собирать следующие категории Персональной информации о Вас во время использования Вами Сервисов:

    </p><p>(i) Персональная информация, предоставленная Вами при регистрации (создании учетной записи), такая как Ваше имя,
      номер телефона, электронная почта, а также информация, предоставленная Подписчиком во время подписки на календари Пользователей;

    </p><p>(ii) электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об
      идентификаторе браузера, информация об аппаратном и программном обеспечении, данные сети wi-fi);

    </p><p>(iii) дата и время осуществления доступа к Сайтам и/или Сервисам;

    </p><p>(iv) информация о Вашей активности во время использования Сайтов и/или Сервисов;

    </p><p>(v) информация о геолокации;

    </p><p>(vi) иная информация о Вас, необходимая для обработки в соответствии с условиями, регулирующими использование конкретных Сайтов или Сервисов Яндекса;

    </p><p>(vii) информация о Подписчиках, которую мы получаем от наших Пользователей

    </p><p>Также Ивент Линк использует файлы cookie и веб-маяки (включая пиксельные теги) для сбора Персональной информации и связывания такой личной информации с Вашим устройством и веб-браузером

    </p><p>5. Какова правовая основа и цели обработки Вашей Персональной информации
      Ивент Линк обрабатывает Вашу Персональную информацию только в том случае, если:

    </p><p>(i) обработка необходима для выполнения договорных обязательств Ивент Линк и Подписчиками и Пользователями,
      включая обеспечение работы Сервисов;

    </p><p>(ii) обработка необходима для соблюдения установленных законодательством обязательств;

    </p><p>Использование в Сервисах Ивент Линк информации, полученной от API Google (далее – «Полученные данные»),
      осуществляется в соответствие с положениями Google API Disclosure с учетом указанных в ней ограничений

    </p><p>6. Как Ивент Линк защищает Вашу Персональную информацию
      В большинстве случаев Персональная информация обрабатывается автоматически без доступа к ней кого-либо
      из сотрудников Ивент Линк. В случае если такой доступ понадобится, то он может быть предоставлен
      только тем сотрудникам Ивент Линк, которые нуждаются в этом для выполнения своих задач.
      Для защиты и обеспечения конфиденциальности данных все сотрудники должны соблюдать внутренние правила и
      процедуры в отношении обработки Персональной информации. Они также должны следовать всем техническим и
      организационным мерам безопасности, действующим для защиты Вашей Персональной информации.

    </p><p>Ивент Линк также внедрил достаточные технические и организационные меры для защиты Персональной информации
      от несанкционированного, случайного или незаконного уничтожения, потери, изменения,
      недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки.

    </p><p>7. Где хранится и обрабатывается Ваша Персональная информация
      Ваша Персональная информация будет храниться в Российской Федерации.

    </p><p>8. Как долго мы храним Вашу Персональную информацию
      Ивент Линк будет хранить Вашу Персональную информацию столько времени, сколько это необходимо для достижения
      цели, для которой она была собрана, или для соблюдения требований законодательства и нормативных актов.

    </p><p>9. Ваши права

    </p><p>В случае если это предусмотрено применимым законодательством, Вы имеете право на доступ к Вашей Персональной
      информации, обрабатываемой Ивент Линк в соответствии с настоящей Политикой.

    </p><p>Если это предусмотрено применимым законодательством, Вы имеете право:

    </p><p>ознакомиться с информацией, которую Ивент Линк хранит о Вас;
    </p><p>требовать удаления Вашей Персональной информации или ее части, а также отзывать согласие на обработку
      Вашей персональной информации;
    </p><p>требовать ограничений на обработку Вашей Персональной информации;
    </p><p>Ивент Линк будет выполнять указанные запросы в соответствии с применимым законодательством.

    </p><p>В случаях, предусмотренных применимым законодательством, Вы можете также обладать другими правами,
      не указанными выше.

    </p><p>При использовании Вами Сервисов передача Персональной информации осуществляется c Вашего согласия,
      выражающего свободную волю и Ваш интерес, (в частности, на основании договора с Вами) и не является
      обязанностью по закону.

    </p><p>Если Вы не удовлетворены тем, как Ивент Линк обрабатывает Вашу Персональную информацию, пожалуйста, сообщите нам,
      и мы рассмотрим Вашу претензию.

    </p><p>10. Как мы используем файлы cookie и другие подобные технологии на Сайтах или при использовании Вами Сервисов

    </p><p>Файлы cookie — это небольшой фрагмент данных, который принимается и обрабатывается устройством,
      которое Вы используете для доступа к Сайтам и Сервисам. Файлы cookie хранят и отправляют обратно на Сайты и Сервисы
      информацию,
      которая помогает Вашей работе с Сайтами и Сервисами и позволяет нам запоминать Ваши предпочтения по истечении
      времени, например, настройки браузера или узнавать Вашу учетную запись.

    </p><p>Ивент Линк использует информацию, содержащуюся в файлах cookie только в указанных выше целях,
      после чего собранные данные будут храниться на Вашем устройстве в течение периода,
      который может зависеть от соответствующего типа файлов cookie, но не превышая срока,
      необходимого для достижения их цели, после чего они будут автоматически удалены из Вашей системы.

    </p><p>Персональная информация, собранная с помощью файлов cookie, размещенных на Вашем устройстве, может быть передана
      и доступна Ивент Линк и/или Пользователям.

    </p><p>Ивент Линк также может использовать веб-маяки (пиксельные теги) для доступа к файлам cookie,
      ранее размещенным на Вашем устройстве, для следующих целей

    </p><p>12. Обновление настоящей Политики
      В настоящую Политику могут быть внесены изменения. Ивент Линк имеет право вносить изменения по своему усмотрению,
      в том числе, но не ограничиваясь, в случаях, когда соответствующие изменения связаны с изменениями в
      применимом законодательстве, а также когда соответствующие изменения связаны с изменениями в работе
      Сайтов и Сервисов.
    </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {
      lang: 'en'
    }
  }
}
</script>

<style lang="scss">
body {
  padding: 20px;
}
.privacy_lang_link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
